<template>
  <div class="contact-form">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <form @submit.prevent="validateForm" class="bg-light p-4">
            <h2 class="mb-4 font-weight-bold">Get In Touch</h2>
            <p class="mb-5 text-danger">
              Please complete the below form and we will be in touch with a
              quick speed
            </p>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="fullName">Full Name: </label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  class="form-control"
                  v-model="fullName"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="email">Email: </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  class="form-control"
                  v-model="email"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="subject">Subject: </label>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  class="form-control"
                  v-model="subject"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="message">Message: </label>
                <textarea
                  name="message"
                  id="message"
                  class="form-control"
                  rows="10"
                  v-model="message"
                ></textarea>
              </div>
            </div>

            <button type="submit" class="btn btn-warning">Send</button>
          </form>
        </div>
        <div class="col-md-6">
          <!-- ALERT ErrorS  -->
          <div class="errors-list">
            <div
              class="alert alert-danger"
              v-for="(err, index) in formErrors"
              :key="index"
            >
              {{ err }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      formErrors: [],
      fullName: "",
      email: "",
      subject: "",
      message: "",
      maxChars: 10
    };
  },
  methods: {
    sendEmail: e => {
      emailjs
        .sendForm(
          "wtxhub_service",
          "template_2s5t92k",
          e.target,
          "user_0EHNtlXZwapTGUr8ZJUOa"
        )
        .then(
          result => {
            console.log("SUCCESS!", result.status, result.text);
          },
          error => {
            console.log("FAILED...", error);
          }
        );
      e.target.reset();
    },

    validateForm(e) {
      // reset form fields
      this.formErrors = [];

      // check fields are empty
      if (!this.fullName) {
        this.formErrors.push("fullName can't be empty");
      }
      if (!this.email) {
        this.formErrors.push("Email can't be empty");
      }
      if (!this.subject) {
        this.formErrors.push("Subject can't be empty");
      }
      if (!this.message) {
        this.formErrors.push("Message can't be empty");
      }

      if (!this.formErrors.length) {
        // return true;
        this.sendEmail(e);
      }

      e.preventDefault();
    }
  }
};
</script>

<style lang="scss">
.contact-form {
  min-height: 500px;
  padding: 40px 0;
  background: url("../assets/contactus.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  h2 {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px;
      height: 2px;
      width: 50px;
      background-color: orange;
    }
  }
}
</style>
